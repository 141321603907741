/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-29 11:28:22
 * @Module: 外部客户应收款项表单
 */
<template>
  <div>
    <el-dialog :title="type=='add'?'添加收款项':type=='revision'?'修改收款项':''" :visible.sync="dialogVisible" width="40%" @closed="closed">
      <div style="padding:20px;">
        <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
          <el-row :gutter="30">

            <el-col :span="12">
              <el-form-item label="金额" prop="amount" :rules="{ required: true, message: '金额不能为空'}">
                <el-input v-model="form.amount" placeholder="请输入金额"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开始日期" prop="startAt" :rules="{ required: true, message: '开始日期不能为空'}">
                <el-date-picker style="width:100%;" value-format="timestamp" v-model="form.startAt" type="date" placeholder="选择开始日期" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止日期" prop="endAt" :rules="{ required: true, message: '截止日期不能为空'}">
                <el-date-picker style="width:100%;" value-format="timestamp" v-model="form.endAt" type="date" placeholder="选择截止日期" />
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>

export default {
  components: {},
  data () {
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        amount: '',
        startAt: null,
        endAt: null,
      },
    };
  },

  mounted () {
  },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postReceivable()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        label: '',
        amount: '',
        deadline: null,
        received: false
      }
    },
    // 添加应收款项
    async postReceivable () {
      const api = {
        revision: {
          func: this.$api.putChangeOuterPayment,
          params: {
            ...this.form,

          }
        },
        add: {
          func: this.$api.postOuterPayment,
          params: {
            ...this.form,
            companyUuid: this.$route.params.uuid
          }
        }
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>